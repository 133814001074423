<template>
  <v-container fluid>
    <FilterDrawer
      @filter="filterComposition"
      @clear="clearFilter"
      currentComponent="composition-filter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td class="text-center">
          <ImageAvatar
            defaultIcon="fas fa-cog"
            :img_src="item.composition_image"
          />
        </td>
        <td>{{ item.name }}</td>
        <td>{{ item.vendor_name }}</td>
        <td class="text-center">
          <StatusChip :currentStatus="item.status" />
        </td>
        <td class="text-right">{{ item.updated_date }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    StatusChip,
    FilterDrawer,
    ImageAvatar,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    dataTable: {
      title: 'Composição',
      new: 'composition-new',
      edit: 'composition-edit',
      context: 'composition-context',
      massAction: 'composition-mass-action',
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'composition_image',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'name' },
        { text: 'Fornecedor', value: 'vendor_name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Atualizado em', value: 'updated_date', align: 'end' },
      ],
    },
  }),
  methods: {
    getComposition() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('product_compositions',{
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          }
        })
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterComposition(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('product_compositions/filter', obj,{
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          }
        })
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getComposition()
    },
  },
  created() {
    this.getComposition()

    contextMenu.$once('action', (type) => {
      if (type === 'compositions') {
        this.getComposition()
      }
    })
    massAction.$once('action', () => {
      this.getComposition()
    })
  },
}
</script>
